import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["wrapper", "checkmark", "button"];

  connect() {
    const radioButtons = this.element.querySelectorAll('input[type="radio"]');

    radioButtons.forEach((radio) => {
      radio.addEventListener("change", (event) => {
        this.wrapperTargets.forEach((wrapper) => {
          wrapper.classList.remove("border-accent-600");
          wrapper.classList.remove("opacity-60");
        });

        this.checkmarkTargets.forEach((checkmark) => {
          checkmark.classList.add("hidden");
        });

        // Add the accent border and show the checkmark for the selected image
        if (event.target.checked) {
          event.target
            .closest(".js-wrapper")
            .classList.remove("border-gray-100");
          event.target
            .closest(".js-wrapper")
            .classList.add("border-accent-600");
          event.target
            .closest(".js-wrapper")
            .getElementsByClassName("js-checkmark")[0]
            .classList.remove("hidden");
        }

        // fade out the other images
        radioButtons.forEach((radio) => {
          if (radio !== event.target) {
            radio.closest(".js-wrapper").classList.add("opacity-60");
          }
        });

        // enable the submit button
        this.buttonTargets.forEach((button) => {
          button.classList.remove("hidden");
          button.disabled = false;
          button.classList.remove("disabled");
        });
      });
    });
  }

  save(event) {
    event.preventDefault();

    event.target.disabled = true;
    event.target.classList.add("disabled");
    event.target.innerHTML = `${this.loadingText}`;

    const url = event.target.getAttribute("href");

    let formData = new FormData();
    formData.append(
      "book[cover]",
      this.element.querySelector('input[type="radio"]:checked').value
    );

    fetch(url, {
      method: "PATCH",
      headers: {
        "X-CSRF-Token": this.csrfToken(),
      },
      body: formData,
    }).then((response) => {
      if (response.ok) {
      } else {
        event.target.disabled = false;
        event.target.innerHTML = "Error. Retry?";
        console.log("Error: ", response);
      }
    });
  }

  csrfToken() {
    return document.querySelector('meta[name="csrf-token"]').content;
  }
}
