import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["radio", "label"];

  connect() {
    this.index = this.findSelectedIndex();
    this.updateDisplay();
  }

  findSelectedIndex() {
    return this.radioTargets.findIndex((target) => {
      return target.querySelector("input").checked;
    });
  }

  updateDisplay() {
    const currentButton = this.radioTargets[this.index];
    if (currentButton) {
      this.labelTarget.textContent =
        currentButton.querySelector("label").textContent;
    }
  }

  previous() {
    this.index =
      (this.index - 1 + this.radioTargets.length) % this.radioTargets.length;
    this.updateSelection();
  }

  next() {
    this.index = (this.index + 1) % this.radioTargets.length;
    this.updateSelection();
  }

  updateSelection() {
    this.radioTargets.forEach((target, index) => {
      const input = target.querySelector("input");
      if (index === this.index) {
        console.log("index", index);

        input.checked = true;
        console.log("input", input);
      }
    });
    this.updateDisplay();
  }
}
